import React from 'react'
import { Logo } from '../components/Logo'
import logo from '../svg/logo.svg'

export default () => (
  <div
    style={{
      background: '#364562',
      color: '#fc4c04',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Logo logoSrc={logo} logoText="Tapend" />
  </div>
)
