import React from 'react'
import PropTypes from 'prop-types'

import logoStyles from './Logo.module.css'

export const Logo = props => {
  const { logoSrc, logoText, logoSize } = props
  return (
    <React.Fragment>
      <img src={logoSrc} style={{ width: logoSize }} alt={logoText} />
      <h1 className={logoStyles.srOnly}>{logoText}</h1>
    </React.Fragment>
  )
}

Logo.defaultProps = { logoSize: '60vmin' }

Logo.propTypes = {
  logoSize: PropTypes.string,
  logoSrc: PropTypes.string.isRequired,
  logoText: PropTypes.string.isRequired
}

export default Logo
